import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import 'bulma/css/bulma.min.css';
import QRCodeStyling from 'qr-code-styling';

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: '/te-symbol.svg',
  dotsOptions: {
    color: '#280071',
    type: 'dots',
  },
  backgroundOptions: {
    color: 'transparent',
  },
  imageOptions: {
    margin: 8,
  },
  cornersDotOptions: {
    type: 'dot',
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
  },
});

const App = () => {
  const [url, setUrl] = useState('https://www.tietoevry.com');
  const [fileExt, setFileExt] = useState('png');
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt,
    });
  };

  return (
    <section className="section">
      <div className="container">
        <input className="input mb-4" value={url} onChange={onUrlChange} />
        <div ref={ref} className="mb-4"/>
        <div className="select">
          <select onChange={onExtensionChange} value={fileExt}>
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>
            <option value="webp">WEBP</option>
          </select>
        </div>
        <button className="button" onClick={onDownloadClick}>
          Download
        </button>
      </div>
    </section>
  );
};

export default App;
